:root {
  --black: #000000;
  --grey: #333333;
  --white: #ffffff;
}

@font-face {
  font-family: 'Inria Sans';
  src: url('./assets/fonts/InriaSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inria Sans';
  src: url('./assets/fonts/InriaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body, .app {
  margin: 0;
  padding: 0;
  font-family: 'Inria Sans';
  color: var(--grey);
}

.largeText{
  font-size: 5vw;
  font-weight: bold;
}

.mediumText{
  font-size: 3vw;
  font-weight: bold;
}

.medium2Text{
  font-size: 2vw;
  font-weight: bold;
}

.smallText{
  font-size: 1.4vw;
  font-weight: normal;
}

.gradient_text {
  background: -webkit-linear-gradient(left, #2F80ED, #9B51E0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.black_text {
  color: var(--black);
}

.button{
  background-color: var(--black);
  color: var(--white);
  border-radius: 5vw;
  border: solid 4px var(--black);
  padding: 1vw 3vw;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover{
  background-color: var(--white);
  color: var(--grey);
}

.button2{
  background-color: #e4996d;
  color: #fcf2dc;
  border-radius: 5vw;
  border: solid 4px #e4996d;
  padding: 1vw 3vw;
  cursor: pointer;
  transition: all 0.3s;
}

.button2:hover{
  background-color: #fcf2dc;
  color: #e4996d;
}

.design_img{
  transition: all 0.3s;
}

.design_img:hover{
  transform: scale(1.1);
}

.button3{
  background-color: #2b5659;
  color: #eafcfa;
  border-radius: 5vw;
  border: solid 4px #2b5659;
  padding: 1vw 3vw;
  cursor: pointer;
  transition: all 0.3s;
}

.button3:hover{
  background-color: #eafcfa;
  color: #2b5659;
}

.button3{
  background-color: #1a2149;
  color: #eafcfa;
  border-radius: 5vw;
  border: solid 4px #1a2149;
  padding: 1vw 3vw;
  cursor: pointer;
  transition: all 0.3s;
}

.button3:hover{
  background-color: #c3eaf0;
  color: #1a2149;
}

.projects-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-flex-item {
  flex: 0 1 48%;
  margin-bottom: 2vw;
}

.project-card{
  transition: all 0.3s;
}

.project-card:hover{
  transform: scale(1.05);
}

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.visible {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.fade_in {
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes moveLeft {
  from {
      transform: translateX(2vw);
  }
  to {
    transform: translateX(0vw);
  }
}

.move_left {
  animation: moveLeft 0.3s ease-out forwards;
}

@keyframes popUp2Animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.popup {
  animation: popUp2Animation 0.6s ease-out forwards;
}