.nav_bar {
    position: fixed;
    right: 3vw;
    top: 3vw;
    z-index: 10;
  }
  
  .menu_icon {
    cursor: pointer;
  }
  
  .menu {
    position: fixed;
    right: 3vw;
    top: 6vw;
    background-color: #ffffff;
    border: 3px solid #333333;
    border-radius: 8px;
    z-index: 3;
  }
  
  .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu ul li {
    padding: 8px 16px;
    border-bottom: 3px solid #333333;
    transition: all 0.3s;
  }
  
  .menu ul li:last-child {
    border-bottom: none;
  }
  
  .menu ul li a {
    text-decoration: none;
    color: #333333;
    display: block;
    transition: all 0.3s;
  }
  
  .menu ul li:hover {
    background-color: #333333;
  }

  .menu ul li:hover a{
    color: white;
  }

@keyframes popUpAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  75% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu {
  animation: popUpAnimation 0.3s ease-out forwards;
}

.menu ul li {
  animation: fadeInAnimation 0.5s ease-out forwards;
}
  